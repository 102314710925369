<template>
  <b-card no-body>
    <b-overlay :show="loading">
      <b-card-body>
        <b-card-title>
          <b-row>
            <b-col>
              <div>Universitas</div>
            </b-col>
            <b-col>
              <div align="right" v-if="allowCreate()">
                <b-button variant="outline-primary" v-b-modal.modal-sm @click="ModalShow()" class="btn-icon"> <feather-icon icon="PlusIcon" class="mr-25" />Tambah</b-button>
              </div>
              <b-modal v-model="Modal" id="modal-tambah" centered size="sm" :title="titleModal">
                <b-card-text>
                  <b-form>
                    <b-form-group label="Universitas" label-for="kategori">
                      <b-form-input id="kategori" v-model="form.name" placeholder="Universitas" />
                    </b-form-group>
                    <b-form-group label="Apakah universitas ini merupakan kedinasan ?" label-for="kedinasan">
                      <b-form-radio-group v-model="form.is_official_school" :options="optSchool" class="demo-inline-spacing" value-field="value" text-field="text" disabled-field="disabled" />
                    </b-form-group>
                  </b-form>
                </b-card-text>

                <template #modal-footer>
                  <div class="w-100">
                    <p class="float-left mb-0"></p>

                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="outline-success" class="mr-1 btn-icon" @click.prevent="Tambah">
                      <feather-icon icon="SaveIcon" class="mr-25" />{{ label || "Tambah" }}
                    </b-button>
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="float-right btn-icon" @click.prevent="tutupModal"> <feather-icon icon="LogOutIcon" class="mr-25" />Tutup </b-button>
                  </div>
                </template>
              </b-modal>
            </b-col></b-row
          >
        </b-card-title>
        <b-card-text class="blog-content-truncate">
          <b-row>
            <b-col md="3" sm="4" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">Per page</label>
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
              </b-form-group>
            </b-col>
            <b-col md="4" sm="8" class="my-1">
              <b-form-group label="Sort" label-cols-sm="2" label-align-sm="right" label-size="sm" label-for="sortBySelect" class="mb-0">
                <b-input-group size="sm">
                  <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-50">
                    <template v-slot:first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>
                  <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-30">
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="5" class="my-1">
              <b-form-group label-for="filterInput" class="mb-0">
                <b-input-group size="sm">
                  <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Search......" />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- <b-col md="2" class="my-1">
            <b-button variant="primary" size="sm"
              ><feather-icon icon="TrashIcon" />Multiple Delete</b-button
            >
          </b-col> -->

            <b-col cols="12">
              <b-table
                striped
                small
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :fields="fields"
                :items="univData"
              >
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(is_official_school)="{ item }">
                  <small>
                    <strong :class="item.is_official_school == 1 ? 'text-success' : 'text-warning'">
                      {{ item.is_official_school == 1 ? 'Sekolah Kedinasan' : 'Bukan Sekolah Kedinasan' }}
                    </strong>
                  </small>
                </template>

                <!-- A custom formatted column -->
                <template #cell(action)="row">
                  <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret v-if="allowUpdate() || allowDelete()">
                    <template v-slot:button-content>
                      <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                    </template>
                    <b-dropdown-item v-if="row.item.is_official_school == 0" :to="{ path: `universitas/detail/${row.item.id}` }">
                      <feather-icon icon="EyeIcon" class="mr-50" />
                      <span>Fakultas/Jurusan</span>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="allowUpdate()" @click="ModalUbah(row.item)">
                      <feather-icon icon="Edit2Icon" class="mr-50" />
                      <span>Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="allowDelete()" @click="ModalHapus(row.item)">
                      <feather-icon icon="TrashIcon" class="mr-50" />
                      <span>Hapus</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
                <!-- A custom formatted column -->

                <!-- A virtual composite column -->
                <template #cell(name)="row">
                  <b-link class="text-dark" :to="{ path: `sekolah-universitas/detail/${row.item.id}` }">{{ row.item.name }}</b-link>
                </template>

                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  {{ data.value }}
                </template>
              </b-table>
              <!-- <b-pagination
          v-if="record !== 0"
          :total="Math.ceil((total * 1) / itemsPerPage)"
          v-model="currentPage"
          @change="staf()"
        /> -->
              <!-- :prev-icon="isRTL ? 'arrow_forward' : 'arrow_back'"
          :next-icon="isRTL ? 'arrow_back' : 'arrow_forward'" -->

              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm" class="my-0" />
            </b-col>
          </b-row>
        </b-card-text>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  VBTooltip,
  BLink,
  BDropdownItem,
  BDropdown,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BDropdownItem,
    BDropdown,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    VBTooltip,
    BLink,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      id: null,
      activeAction: null,
      label: "Simpan",
      //Data
      univData: [],
      form: {
        id: null,
        name: null,
        is_official_school: 0,
      },
      optSchool: [
        { text: "Ya", value: 1, disabled: false },
        { text: "Tidak", value: 0, disabled: false },
      ],
      //Modal
      titleModal: null,
      Modal: false,
      ModalEdit: false,
      //Table
      fields: [
        { key: "index", label: "No" },
        { key: "name", label: "Universitas", sortable: true },
        { key: "is_official_school", label: "Kategori Sekolah", sortable: true },
        { key: "action", label: "#" },
      ],
      selected: [],
      total: [],
      record: [],
      optFilter: [
        { id: "name", value: "Universitas" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // filter: "Universitas",
      search: null,
      idd: null,
      loading: false,
    };
  },
  watch: {},
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    tutupModal() {
      this.Modal = false;
      this.resetForm();
      this.getData();
    },
    resetForm() {
      this.form = {
        id: null,
        name: null,
      };
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.titleModal = "Tambah Universitas";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = item.id;
      this.form = item;
      this.activeAction = "ubah";
      this.titleModal = "Ubah Universitas";
      this.Modal = true;
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          data.fungsi = 2; //soft delete
          this.$store
            .dispatch("masterUniv/createUpdateDelete", [data])
            .then(() => {
              this.getData();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    Tambah() {
      if (this.form.name == null || this.form.name == "") {
        this.pesanGagal();
        return false;
      }
      let payload = {
        name: this.form.name,
        is_official_school: this.form.is_official_school == null ? 0 : this.form.is_official_school,
      };

      if (this.id) {
        payload.id = this.id;
      }

      this.$store
        .dispatch("masterUniv/createUpdateDelete", [payload])
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.resetForm();
          this.Modal = false;
          // this.ModalEdit = false;
          this.getData();
          this.displaySuccess({
            text: "Universitas berhasil di" + this.activeAction,
          });
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getData() {
      this.loading = true;
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "name",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        is_official_school: null,
      };
      this.$store
        .dispatch("masterUniv/index", payload)
        .then((response) => {
          this.loading = false;
          let univData = response.data.data;
          this.univData = univData;
          this.totalRows = this.univData.length;
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        })
        .finally(() => {
          this.selected = [];
        });
    },
  },
  created() {},
  async mounted() {
    this.getData();
  },
};
</script>

<style></style>
