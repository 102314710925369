var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card-body', [_c('b-card-title', [_c('b-row', [_c('b-col', [_c('div', [_vm._v("Universitas")])]), _c('b-col', [_vm.allowCreate() ? _c('div', {
    attrs: {
      "align": "right"
    }
  }, [_c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-sm",
      modifiers: {
        "modal-sm": true
      }
    }],
    staticClass: "btn-icon",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.ModalShow();
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "PlusIcon"
    }
  }), _vm._v("Tambah")], 1)], 1) : _vm._e(), _c('b-modal', {
    attrs: {
      "id": "modal-tambah",
      "centered": "",
      "size": "sm",
      "title": _vm.titleModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100"
        }, [_c('p', {
          staticClass: "float-left mb-0"
        }), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "mr-1 btn-icon",
          attrs: {
            "type": "submit",
            "variant": "outline-success"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.Tambah($event);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "SaveIcon"
          }
        }), _vm._v(_vm._s(_vm.label || "Tambah") + " ")], 1), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "float-right btn-icon",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.tutupModal($event);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "LogOutIcon"
          }
        }), _vm._v("Tutup ")], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.Modal,
      callback: function callback($$v) {
        _vm.Modal = $$v;
      },
      expression: "Modal"
    }
  }, [_c('b-card-text', [_c('b-form', [_c('b-form-group', {
    attrs: {
      "label": "Universitas",
      "label-for": "kategori"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "kategori",
      "placeholder": "Universitas"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Apakah universitas ini merupakan kedinasan ?",
      "label-for": "kedinasan"
    }
  }, [_c('b-form-radio-group', {
    staticClass: "demo-inline-spacing",
    attrs: {
      "options": _vm.optSchool,
      "value-field": "value",
      "text-field": "text",
      "disabled-field": "disabled"
    },
    model: {
      value: _vm.form.is_official_school,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "is_official_school", $$v);
      },
      expression: "form.is_official_school"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-card-text', {
    staticClass: "blog-content-truncate"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "3",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "4",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Sort",
      "label-cols-sm": "2",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "sortBySelect"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "sortBySelect",
      "options": _vm.sortOptions
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("-- none --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sortBy,
      callback: function callback($$v) {
        _vm.sortBy = $$v;
      },
      expression: "sortBy"
    }
  }), _c('b-form-select', {
    staticClass: "w-30",
    attrs: {
      "size": "sm",
      "disabled": !_vm.sortBy
    },
    model: {
      value: _vm.sortDesc,
      callback: function callback($$v) {
        _vm.sortDesc = $$v;
      },
      expression: "sortDesc"
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Asc")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("Desc")])])], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "5"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search",
      "placeholder": "Search......"
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function click($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn,
      "fields": _vm.fields,
      "items": _vm.univData
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(index)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(is_official_school)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('small', [_c('strong', {
          class: item.is_official_school == 1 ? 'text-success' : 'text-warning'
        }, [_vm._v(" " + _vm._s(item.is_official_school == 1 ? 'Sekolah Kedinasan' : 'Bukan Sekolah Kedinasan') + " ")])])];
      }
    }, {
      key: "cell(action)",
      fn: function fn(row) {
        return [_vm.allowUpdate() || _vm.allowDelete() ? _c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [row.item.is_official_school == 0 ? _c('b-dropdown-item', {
          attrs: {
            "to": {
              path: "universitas/detail/".concat(row.item.id)
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "EyeIcon"
          }
        }), _c('span', [_vm._v("Fakultas/Jurusan")])], 1) : _vm._e(), _vm.allowUpdate() ? _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.ModalUbah(row.item);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "Edit2Icon"
          }
        }), _c('span', [_vm._v("Edit")])], 1) : _vm._e(), _vm.allowDelete() ? _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.ModalHapus(row.item);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "TrashIcon"
          }
        }), _c('span', [_vm._v("Hapus")])], 1) : _vm._e()], 1) : _vm._e()];
      }
    }, {
      key: "cell(name)",
      fn: function fn(row) {
        return [_c('b-link', {
          staticClass: "text-dark",
          attrs: {
            "to": {
              path: "sekolah-universitas/detail/".concat(row.item.id)
            }
          }
        }, [_vm._v(_vm._s(row.item.name))])];
      }
    }, {
      key: "cell()",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.value) + " ")];
      }
    }])
  }), _c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }